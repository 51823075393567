import axios from 'axios';
import './OrdersDashboard.css';
import { useRef } from 'react';
import { useEffect } from 'react';
import React, { useState } from 'react';
import Tooltip ,{ tooltipClasses } from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import AuthToken from '../../context/AuthToken';
import ClearIcon from '@mui/icons-material/Clear';
import { TOAST_TYPE } from '../../utils/constants';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import BulkOrderTags from '../ProjectIdPage/ProjectTags';
import { useScreenType } from '../../utils/commonMethods';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Pagination from '../../components/Pagination/Pagination';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import OrdersTable from '../../components/OrdersTable/OrdersTable';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { ORDER_LIST_URL, CREATE_PROPERTY_URL , MOLE_ORDER_URL, FETCH_FEATURE_URL,  ORDER_URL  } from '../../helper/ApiUrl';
import InputIcon from '@mui/icons-material/Input';
import MoveToFolderImg from '../../assets/moveToFolder.svg'
import CustomModal from './../../components/CustomModal/CustomModal.jsx';
import useMediaQuery from '@mui/material/useMediaQuery';

import Checkbox from '@mui/material/Checkbox';
import ReportIcon from '@mui/icons-material/Report';


import { styled } from '@mui/material/styles';
import PopUpBox from "../../components/PopUpBox/PopUpBox";

const OrdersDashboard = ({ handleSuccess, handleError }) => {
  const isSmallerThan820px = useMediaQuery('(max-width:820px)');
  let filterURL = '';
  const menuRef = useRef([]);
  menuRef.current = [];
  const token = AuthToken();
  const tagRef = useRef();
  const navigate = useNavigate();
  const deleteOrderRef = useRef();
  const MoveOrderRef = useRef();
  const deleteOrderInnerRef = useRef();
  const isMobile = useScreenType();
  const [menuBar, setMenuBar] = useState([]);
  const [address, setAddress] = useState('');
  const [active, setActive] = useState(false);
  const [orderTags, setOrderTags] = useState([]);
  const [activeTag, setActiveTag] = useState(false);
  const [filterState, setFilterState] = useState();
  const [rowCount, setRowCount] = useState(0);
  const [orderList, setOrderList] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [inputValue, setInputValue] = useState(
    localStorage.getItem('rowsPerPage') || '10'
  );
  const [invalidInput, setInvalidInput] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [criteriaName, setCriteriaName] = useState('Criteria');

  const [searchParams, setSearchParams] = useSearchParams();
  const tags = searchParams.get('tags') || '';
  const pageNum = searchParams.get('page') || 1;
  const min_area = searchParams.get('min_area') || '';
  const max_area = searchParams.get('max_area') || '';
  const user_id = searchParams.get('user_id') || '';
  const end_Date = searchParams.get('end_date') || '';
  const start_Date = searchParams.get('start_date') || '';
  const property = searchParams.get('status') || '';
  const oprtn = searchParams.get('operation') || 'in';
  const [isNot, setIsNot] = useState(oprtn);
  const [minArea, setMinArea] = useState(min_area);
  const [maxArea, setMaxArea] = useState(max_area);
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(pageNum);
  const [jumpToInput, setJumpToInput] = useState(currentPage);
  const [endDate, setEndDate] = useState({ endDate: end_Date });
  const [startDate, setStartDate] = useState({ startDate: start_Date });
  const [userId, setUserId] = useState(user_id.split(/,+/).filter(Boolean));
  const [status, setStatus] = useState(property.split(/,+/).filter(Boolean));
  const [selectTagArray, setSelectTagArray] = useState(
    tags.split(/,+/).filter(Boolean)
  );

  const lastPage = rowCount / inputValue;
  const firstRow = (currentPage - 1) * inputValue + 1;
  const lastRow = Math.min(currentPage * inputValue, rowCount);
  const [selectedStatus,setSelectedStatus]=useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  // for the  DIY order delete from the toolbar
  const [actionId, setActionId] = useState();


  // const handlePageChange = (event) => {
  //   const newValue = event.target.value;
  //   setInputValue(newValue);

  //   if (newValue >= 5 && newValue <= 20) {
  //     setInvalidInput(false);
  //   } else {
  //     setInvalidInput(true);
  //   }
  // };
  

 
  const  handelCancelFunc =()=>{
    document.querySelector(".wrapperPopUpBox").style.display = "none";
  }

  const handelDeleteFunc = ()=>{
    if (isAllSelected) {
      deleteAllOrders();
    } else {
      deleteBulkDraftOrder();
    }
    document.querySelector(".wrapperPopUpBox").style.display = "none";
  }

  // getting the Bulk order project list
  const [projectList, setProjectList] = useState();
  const getProjectList = () => {
    console.log("Invoked")
    axios({
      // url: MOLE_ORDER_URL,
      url:  `${MOLE_ORDER_URL}?p=${1}&psz=${500}`,
      method: "GET",
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        setProjectList(res.data.data);
        console.log(res.data.data, " MOLE_ORDER_URL in order dashboard")
      })
      .catch((err) => {
        handleError(err);
      });
  };

  useEffect(()=>{
  
    getProjectList()
  },[])
  
  // getting the Bulk order project list 



  const handleKeyDown = (event) => {
    const input = event.target.value;
    if (event.key === 'Enter') {
      event.preventDefault();
      if (input) {
        if (input < 0) {
          setJumpToInput(1);
          setCurrentPage(1);
        } else if (input > pageCount) {
          setJumpToInput(pageCount);
          setCurrentPage(pageCount);
        } else setCurrentPage(input);
      }
    }
  };

  const handlePreviousPage = () => {
    setActiveTag(!activeTag);
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(rowCount / inputValue);
    if (currentPage < totalPages) {
      setActiveTag(!activeTag);
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    searchParams.set('page', currentPage);
    setSearchParams(searchParams);
  }, [currentPage]);

  const setURL = () => {
    if (address) {
      filterURL += `&address=${address}`;
    }
    if (property) {
      filterURL += `&status=${property}`;
    }
    if (minArea) {
      filterURL += `&min_area=${minArea}`;
    }
    if (maxArea) {
      filterURL += `&max_area=${maxArea}`;
    }
    if (userId.length > 0) {
      let newId = '';
      userId.forEach((item) => (newId = newId + item + ','));
      filterURL += `&user_id=${newId.slice(0, newId.length - 1)}`;
    }
    // if (isMobile) {
    //   filterURL += `&status=measurement_completed`;
    // }
    if (status.length > 0) {
      let newStatus = '';
      status.forEach((item) => (newStatus = newStatus + item + ','));
      filterURL += `&status=${newStatus.slice(0, newStatus.length - 1)}`;
    }
    if (startDate.startDate || endDate.endDate) {
      filterURL += `&start_date=${startDate.startDate || start_Date}&end_date=${endDate.endDate || end_Date
        }`;
    }
    if (isNot && selectTagArray.length > 0) {
      let tags = '';
      selectTagArray.forEach((tag) => (tags += tag + ','));
      filterURL += `&tags=${tags.slice(
        0,
        tags.length - 1
      )}&tag_operation=${isNot}`;
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      let isPresentInRef = false;
      for (let i in menuRef.current) {
        if (menuRef.current[i].contains(e.target)) {
          isPresentInRef = true;
        }
      }
      if (menuBar.length && menuRef.current && !isPresentInRef) {
        setIsNot('');
        setStatus([]);
        setMenuBar([]);
        setSelectTagArray([]);
        setCriteriaName('Criteria');
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [menuBar]);

  const getOrderList = () => {
    setURL();
    setFilterState(filterURL);
    axios
      .get(`${ORDER_LIST_URL}?p=${currentPage}&psz=${inputValue}${filterURL}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        console.log('Res', response);
        setOrderList(response.data.data);
        setRowCount(response.data.count);
        // setPageCount(Math.ceil(response.data.count / 10));
        setPageCount(response.data.total_pages);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate('/access-denied');
        } else {
          console.error(error.message);
        }
      });
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    if (params.get('address')) {
      setAddress(params.get('address'));
    }

    if (params.get('min_area')) {
      setMinArea(params.get('min_area'));
    }
    if (params.get('max_area')) {
      setMaxArea(params.get('max_area'));
    }

    if (params.get('user_id')) {
      setUserId(params.get('user_id').split(','));
    }

    if (params.get('status')) {
      setStatus(params.get('status').split(','));
    }

    if (params.get('start_date') || params.get('end_date')) {
      setStartDate({ startDate: params.get('start_date') });
      setEndDate({ endDate: params.get('end_date') });
    }

    if (params.get('operation') || params.get('tags')) {
      setIsNot(params.get('operation', isNot));
      setSelectTagArray(params.get('tags').split(','));
    }

    if (!invalidInput) {
      getOrderList();
    }
  }, [address, active, currentPage, activeTag, inputValue]);

  const handleAreaFilter = () => {
    if (minArea || maxArea) {
      setSelectedIds([]);
      getOrderList();
      searchParams.set('min_area', minArea ? minArea : null);
      searchParams.set('max_area', maxArea ? maxArea : null);
      setSearchParams(searchParams);
    }
    setMenuBar([]);
  };

  const handleDateFilter = () => {
    if (startDate.startDate || endDate.endDate) {
      const start = new Date(startDate.startDate);
      const end = new Date(endDate.endDate);

      if (end < start) {
        handleSuccess(
          TOAST_TYPE.WARNING,
          'End date cannot be earlier than start date'
        );
        return;
      }
      setSelectedIds([]);
      getOrderList();
      searchParams.set(
        'start_date',
        startDate.startDate ? startDate.startDate : null
      );
      searchParams.set('end_date', endDate.endDate ? endDate.endDate : null);
      setSearchParams(searchParams);
    }
    setMenuBar([]);
  };

  const handleUserFilter = () => {
    if (userId.length > 0) {
      setSelectedIds([]);
      getOrderList();
      let newId = '';
      userId.forEach((item) => (newId = newId + item + ','));
      searchParams.set(
        'user_id',
        newId ? newId.slice(0, newId.length - 1) : null
      );
      setSearchParams(searchParams);
    }
    setMenuBar([]);
  };

  const handleStatusFilter = () => {
    if (status) {
      setSelectedIds([]);
      getOrderList();
      let newStatus = '';
      status.forEach((item) => (newStatus = newStatus + item + ','));
      searchParams.set(
        'status',
        newStatus ? newStatus.slice(0, newStatus.length - 1) : null
      );
      setSearchParams(searchParams);
    }
    setMenuBar([]);
  };

  const handleTagsFilter = () => {
    if (selectTagArray.length > 0) {
      let tags = '';
      selectTagArray.forEach((tag) => (tags += tag + ','));
      searchParams.set('operation', isNot);
      searchParams.set('tags', tags.slice(0, tags.length - 1));
      setSearchParams(searchParams);
      setSelectedIds([]);
      getOrderList();
    } else {
      handleSuccess(TOAST_TYPE.WARNING, 'Please select any tag!');
    }
  };

  const clearAreaFilter = () => {
    setMinArea('');
    setMaxArea('');
    setActive(!active);
    searchParams.delete('min_area');
    searchParams.delete('max_area');
    setSearchParams(searchParams);
  };

  const clearUserFilter = () => {
    setUserId([]);
    setActive(!active);
    searchParams.delete('user_id');
    setSearchParams(searchParams);
  };

  const clearDateFilter = () => {
    setStartDate({ startDate: '' });
    setEndDate({ endDate: '' });
    setActive(!active);
    searchParams.delete('start_date');
    searchParams.delete('end_date');
    setSearchParams(searchParams);
  };

  const clearStatusFilter = () => {
    setStatus('');
    setActive(!active);
    searchParams.delete('status');
    setSearchParams(searchParams);
  };

  const clearTagsFilter = () => {
    setIsNot('in');
    setActive(!active);
    setSelectTagArray([]);
    searchParams.delete('tags');
    setCriteriaName('Criteria');
    searchParams.delete('operation');
    setSearchParams(searchParams);
  };

  const clearAllFilter = () => {
    filterURL = '';
    setAddress('');
    setMinArea('');
    setMaxArea('');
    setStatus('');
    setUserId([]);
    setIsNot('in');
    setAddress('');
    setActive(!active);
    setSelectTagArray([]);
    setCriteriaName('Criteria');
    setStartDate({ startDate: '' });
    searchParams.delete('tags');
    searchParams.delete('user_id');
    searchParams.delete('operation');
    searchParams.delete('min_area');
    searchParams.delete('max_area');
    searchParams.delete('start_date');
    searchParams.delete('end_date');
    searchParams.delete('status');
    setSearchParams(searchParams);
    // getOrderList();
  };

  const addToRefs = (el) => {
    if (el && !menuRef.current.includes(el)) {
      menuRef.current.push(el);
    }
  };

  const handlePageChange = (data) => {
    let currentPage = data.selected + 1;
    setCurrentPage(currentPage);
    setSelectedIds([]);
    searchParams.set('page', currentPage);
    setActiveTag(!activeTag);
    setSearchParams(searchParams);
    const tableContainer = document.getElementById('yourTableContainerId');
    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }
  };

  const deleteBulkDraftOrder = () => {
    let deleteIds = '';
    selectedIds.forEach((id) => (deleteIds += id + '%2C'));
    deleteIds = deleteIds.slice(0, deleteIds.length - 3);
    axios
      .delete(`${CREATE_PROPERTY_URL}?id=${deleteIds}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        getOrderList();
        setSelectedIds([]);
        console.log(response,"response")
        handleSuccess(TOAST_TYPE.SUCCESS, 'Order Deleted Successfully!');
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleMatchOrder = () => {
    if (selectedIds?.length !== orderList?.length) {
      const ids = [];
      orderList.map((item) => ids.push(item.id));
      setSelectedIds([...ids]);
    }
    axios
      .get(`${ORDER_LIST_URL}?match_count=${rowCount}${filterState}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setIsAllSelected(response?.data?.count_match);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const deleteAllOrders = () => {
    axios
      .delete(
        `${ORDER_LIST_URL}?match_count=${rowCount}${filterState}&delete_all=${isAllSelected}`,
        {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        }
      )
      .then((response) => {
        getOrderList();
        setSelectedIds([]);
        handleSuccess(TOAST_TYPE.SUCCESS, 'Order Deleted Successfully!');
      })
      .catch((error) => {
        handleError(error);
      });
  };

// Delete DIY order 
const deleteDIYOrder = () => {
  console.log(actionId,"actionId ")
  axios
    .delete(`${ORDER_URL}${actionId}`, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: 'application/json',
      },
    })
    .then((response) => {
      setMenuBar({});
      setActiveTag(!activeTag);
      handleSuccess(TOAST_TYPE.SUCCESS, 'Order Deleted Successfully!');
    })
    .catch((error) => {
      console.log(error.message," error message ")
      console.error(error.message);
    });
};





// Delete DIY order 





  // Moved order 
  const [bulkOrderId, setBulkOrderId]= useState()
 const handleMoveForm = ()=>{
  document.querySelector(".move-box-form").style.display="block"
  console.log("click handleMoveForm")
 }
 const functionalUpdateBulkOrderId = (projectId)=>{

  setBulkOrderId(projectId)
 }
 const handleBulkNamecheckbox =(e,projectId)=>{
  functionalUpdateBulkOrderId(projectId)
  // console.log(e," e click handleMoveForm")
  // console.log(projectId,"projectId handleMoveForm")
  // console.log(bulkOrderId,"bulkOrderId handleMoveForm")
 }

 // movehandler

 const handleMoveOrder = () => {
  
  const movedData =
 
  {
   
    bulk_order_id: bulkOrderId  ,
    order_ids : selectedIds,
    
    
  };
  const orderIdParam = selectedIds.every(id => typeof id === 'string') ? 'true' : 'false';

console.log(bulkOrderId,"bulkOrderId Before Axios Call")
console.log(checkedState,"bulkOrderId Before Axios Call")

  axios({
    // url:`${MOLE_ORDER_URL}/${bulkOrderId}/move?order_id=${orderIdParam}`,
    url:`${MOLE_ORDER_URL}/bulk/move?order_id=${orderIdParam}`,
    method:'PATCH',
    data:movedData,
    headers:{
      Authorization: `Token ${token}`,
      Accept: 'application/json',
    }
  })
    
    .then((response) => {
      // /mole/order/<bulk_oid>/move

       
      console.log(response,"patch response on move")
      document.querySelector(".move-box-form").style.display="none"
      getOrderList()
      handleSuccess(TOAST_TYPE.SUCCESS, 'Order Moved Successfully!');
    
      setSelectedIds([]);
     
    })
    .catch((error) => {
      handleError(error);
    });
};


const [checkedState, setCheckedState] = useState({});

  const handleBulkNameCheckbox = (e, projectId) => {
  setBulkOrderId(projectId)
    const isChecked = e.target.checked;
    setCheckedState((prevState) => ({
      ...prevState,
      [projectId]: isChecked,
    }));
  };

  const handleDivClick = (projectId) => {
  setBulkOrderId(projectId)
    setCheckedState((prevState) => {
      const newState = { ...prevState, [projectId]: !prevState[projectId] };
      return newState;
    });
  };

// for custom tooltip fontsize

// const CustomTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     fontSize: '18px',
//   },
// }));


// for custom tooltip fontsize

  return (
    <div className="orders-dashboard">
      <BulkOrderTags
        tagRef={tagRef}
        orderTags={orderTags}
        activeTag={activeTag}
        selectedIds={selectedIds}
        setActiveTag={setActiveTag}
        setOrderTags={setOrderTags}
        setSelectedIds={setSelectedIds}
      />
      <div className="orders-dashboard-top-tollbar" style={ selectedIds?.length > 0 ? { display: 'block' } : { display: 'none' }} >
        <div className="dashboard-top-tollbar-contant-container">
          <div className="property-selection-count">
            {isAllSelected ? (
              <div>{rowCount} properties selected</div>
            ) : (
              <div>
                Selected : {selectedIds?.length}/{orderList?.length}
              </div>
            )}

            {isAllSelected ? (
              <div onClick={() => { setSelectedIds([]); setIsAllSelected(false) }} className="select-all-backend-props">
                Clear Selection
              </div>
            ) : (
              <div className="select-all-backend-props" onClick={handleMatchOrder}>
                Select {rowCount} properties
              </div>
            )}
          </div>
          <div
            className="tollbar-dismiss-container"
            style={isAllSelected ? { left: '-10%' } : { left: '-9%' }}
            onClick={() => {
              setOrderTags([]);
              setSelectedIds([]);
              setIsAllSelected(false);
              tagRef.current.style.display = 'none';
              // deleteOrderRef.current.style.opacity = 0;
              // deleteOrderRef.current.style.pointerEvents = 'none';
              document.querySelector(".move-box-form").style.display="none"

            }}
          >
            DISMISS <CloseIcon style={{fontSize: "13px", marginLeft: "5px"}} />
          </div>
          <div className="top-tollbar-action-container">

          <Tooltip title="Move to Bulk Order" placement="bottom"  className='fontsizeincrease'
          
          >

              {/* <MoveUpIcon className="top-tollbar-action-icon" /> */}
              {/* <button style={{ padding:"0.2rem 0.4rem",marginRight:"0.2rem", cursor:"pointer",borderRadius:"5px", background:"green",color:"white",border:"none"}}
                onClick={handleMoveForm}
              > Move</button> */}
              {/* <button style={{ width:"2rem",marginRight:"0.2rem", cursor:"pointer",borderRadius:"5px", border:"none" ,background:"none",color:"#06372a"}}
                onClick={handleMoveForm}
              >
               <img src={ MoveToFolderImg}   onClick={handleMoveForm}  style={{ cursor:"pointer" }} />
              </button> */}
              <img src={ MoveToFolderImg}   onClick={handleMoveForm}  style={{ cursor:"pointer" ,width:"1.5rem"}} />
             
            
          </Tooltip>
          {/* <CustomTooltip title="Move into Bulk Order" placement="bottom">  <img src={ MoveToFolderImg}   onClick={handleMoveForm}  style={{ cursor:"pointer" ,width:"1.5rem"}} /></CustomTooltip> */}
            <Tooltip title="Tag" placement="bottom">
              <LocalOfferIcon
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTag(!activeTag);
                  tagRef.current.style.display = 'block';
                  document.querySelector(".move-box-form").style.display="none"
                }}
                className="top-tollbar-action-icon"
              />
            </Tooltip>
            
            {/* <Tooltip title="Archive" placement="bottom">
              <ArchiveIcon className="top-tollbar-action-icon" />
            </Tooltip>  */}
            <Tooltip title="Delete" placement="bottom">
              <DeleteIcon
                onClick={() => {
                  setIsDeleteModalOpen(true)
                  // deleteOrderRef.current.style.opacity = 1;
                  // deleteOrderRef.current.style.pointerEvents = 'auto';
                  document.querySelector(".wrapperPopUpBox").style.display = "block";
                  document.querySelector(".move-box-form").style.display="none"

                }}
                // className="top-tollbar-action-icon"
                className={(selectedStatus.includes('diy')) || (selectedStatus.includes('draft')) ? "top-tollbar-action-icon" : (selectedStatus.includes('diy')) && (selectedStatus.includes('draft')) && (selectedStatus.includes('in_progress')) && (selectedStatus.includes('measurement_completed')) ? "top-tollbar-action-icon" : "top-tollbar-action-icon-disable"}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      {/* <div ref={deleteOrderRef} className="no-layers-info-container">
        <div
          ref={deleteOrderInnerRef}
          className="delete-layer-inner-modal delete-all-layer-inner-modal"
        >
          <div className="view-modal-top">
            <span
              onClick={() => {
                deleteOrderRef.current.style.opacity = 0;
                deleteOrderRef.current.style.pointerEvents = 'none';
              }}
            >
              X
            </span>
          </div>
          <div className="no-layer-mid-container">
            <p>
              Are you sure? You want to delete these orders! This action can't
              be undone.
            </p>
            <p style={{ marginBottom: 0, paddingBottom: 0, fontWeight: '600' }}>
              Note:   orders can be deleted.
            </p>
          </div>
          <div className="view-modal-bttns">
            <button
              onClick={() => {
                deleteOrderRef.current.style.opacity = 0;
                deleteOrderRef.current.style.pointerEvents = 'none';
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (isAllSelected) {
                  deleteAllOrders();
                } else {
                  deleteBulkDraftOrder();
                }
                deleteOrderRef.current.style.opacity = 0;
                deleteOrderRef.current.style.pointerEvents = 'none';
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div> */}


      <CustomModal additionalText="Only DIY and Draft orders can be deleted." icon={<ReportIcon sx={{color: "#519259", fontSize: "3rem"}} />} h="fit-content !important" maxH="fit-content !important" cancelText="Cancel" submitText="Delete" submit={() => handelDeleteFunc(false)} title="Delete orders?" description="This action can't be undone." setOpen={setIsDeleteModalOpen} open={isDeleteModalOpen}/>

       {/* <PopUpBox
        //imageSrc={}
        title="Delete orders?"
        message="This action can't be undone."
         additionalMsg ="Only DIY and Draft orders can be deleted."
        leftBtnText="Cancel"
        rightBtnText="Delete"
        onLeftBtnfunc={handelCancelFunc}
         onRightBtnfunc={handelDeleteFunc}
      />  */}


      {/* MOVE into Bulk order Form  */}

      
      <div className='move-box-form'>

      <div className='Move-overlay' ></div>
      <div className='move-into-bulk'> 
         <div style={{ position:"fixed", top:"5px ", right:"5px ", cursor:"pointer"}}>
          <button onClick={()=>{  
             document.querySelector(".move-box-form").style.display="none" }}
             style={{background:"none", border:"none", cursor:"pointer", color:"black", fontSize:"1.6rem"}}> &#215;</button></div>
        <h3 style={{fontSize:"clamp(20px, 4vw, 1.50vw)"}}>Select name to move into bulk order.</h3>
        {/* <div className='move-into-bulk-option-container'>
             { projectList?.map(

            (project, index)=>{
             
             return (
             <div key={index} className='checkbox-and-name' onClick={()=>{ console.log("clcked")} } style={{ cursor:"pointer"}}> 
             <input type='checkbox'
              onChange={(e)=>{ handleBulkNamecheckbox(e,project?.id)}}
             />
            {project?.name} </div>
           )
            
            }
           )}
        </div> */}
        <div className='move-into-bulk-option-container'>
      {projectList?.map((project, index) => (
        <div
          key={index}
          className='checkbox-and-name'
          onClick={() => handleDivClick(project?.id)}
          style={{ cursor: 'pointer' }}
        >

          <Checkbox
                size="small"
                        color="success"
            checked={checkedState[project?.id] || false}
            onChange={(e) => handleBulkNameCheckbox(e, project?.id)}
            onClick={(e) => e.stopPropagation()} // Prevent div click event from firing
          />
          {project?.name}
        </div>
      ))}
    </div>

          <div className="view-modal-bttns"  > <button onClick={handleMoveOrder } style={{background:" #519259", color:"white",textWrap:"no-wrap"}} >Move Order</button> </div> 
        
        {/* style={{padding:"0.4rem 0.8rem", margin:"0.4rem auto ", color:"white",border:"none"}} */}
      </div>
      </div>
      


      {/* MOVE Form  */}


      <div className="orders-dashboard-search">

        {/* <div className="orders-dashboard-search-left">
          <input
            style={{height: "35px", width: "100%"}}
            type="text"
            value={address}
            placeholder="Search by Order ID/Address"
            onChange={(e) => setAddress(e.target.value)}
          />
          <SearchOutlinedIcon className="orders-search-icon" />
          <button style={{height: "40px", marginLeft: "10px"}} onClick={clearAllFilter}>
            <FilterListOffIcon className="clear-filter-icon" />
            Clear Filters
          </button>
        </div> */}
        <div className="orders__dashboard__searchLeft orders_dashboard_header_mobile" >
          <div  className="left__input__box" style={{ width: "98%"}} >
          <input
           id="left__input__"
           
            type="text"
            value={address}
            placeholder="Search by Order ID/Address"
            onChange={(e) => setAddress(e.target.value)}
          />
          <SearchOutlinedIcon className="Search__icon" />
          </div>
          { !isSmallerThan820px && <button style={{height: "40px", marginLeft: "10px"}} onClick={clearAllFilter}>
            <FilterListOffIcon className="clear-filter-icon" />
            Clear Filters
          </button>}
        </div>


        <div className="orders-dashboard-search-right">
          {min_area || max_area ? (
              <div className="filter-div">
                <span>Area</span>
                <ClearIcon
                  className="close-filter-icon"
                  onClick={clearAreaFilter}
                />
              </div>
          ) : null}
          {user_id ? (
              <div className="filter-div">
                <span>Creator</span>
                <ClearIcon
                  className="close-filter-icon"
                  onClick={clearUserFilter}
                />
              </div>
          ) : null}
          {start_Date || end_Date ? (
              <div className="filter-div">
                <span>Created On.</span>
                <ClearIcon
                  className="close-filter-icon"
                  onClick={clearDateFilter}
                />
              </div>
          ) : null}
          {property && !isMobile ? (
              <div className="filter-div">
                <span>Status</span>
                <ClearIcon className="close-filter-icon" onClick={clearStatusFilter} />
              </div>
          ) : null}
          {tags ? (
              <div className="filter-div">
                <span>Tags</span>
                <ClearIcon
                  className="close-filter-icon"
                  onClick={clearTagsFilter}
                />
              </div>
          ) : null}
        </div>
      </div>
      <div className="orders-dashboard-table">
        <OrdersTable
          clearAllFilter={clearAllFilter}
          isNot={isNot}
          status={status}
          userId={userId}
          menuBar={menuBar}
          setIsNot={setIsNot}
          activeTag={activeTag}
          addToRefs={addToRefs}
          minArea={minArea}
          maxArea={maxArea}
          endDate={endDate}
          startDate={startDate}
          pageCount={pageCount}
          orderList={orderList}
          setStatus={setStatus}
          setUserId={setUserId}
          setMenuBar={setMenuBar}
          setMinArea={setMinArea}
          setMaxArea={setMaxArea}
          setEndDate={setEndDate}
          filterState={filterState}
          selectedIds={selectedIds}
          handleError={handleError}
          criteriaName={criteriaName}
          setStartDate={setStartDate}
          setActiveTag={setActiveTag}
          setOrderList={setOrderList}
          isAllSelected={isAllSelected}
          handleSuccess={handleSuccess}
          setSelectedIds={setSelectedIds}
          selectTagArray={selectTagArray}
          setCriteriaName={setCriteriaName}
          setIsAllSelected={setIsAllSelected}
          handleUserFilter={handleUserFilter}
          handleAreaFilter={handleAreaFilter}
          handleTagsFilter={handleTagsFilter}
          handleDateFilter={handleDateFilter}
          setSelectTagArray={setSelectTagArray}
          handleStatusFilter={handleStatusFilter}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
      </div>
      <Pagination
        invalidInput={invalidInput}
        jumpToInput={jumpToInput}
        setJumpToInput={setJumpToInput}
        handleKeyDown={handleKeyDown}
        handlePageChange={handlePageChange}
        pageCount={pageCount}
        currentPage={currentPage}
        inputValue={inputValue}
        rowCount={rowCount}
        setInputValue={setInputValue}
      />
      
    </div>
  );
};

export default OrdersDashboard;
