import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, Typography, IconButton } from '@mui/material';
import './ExplorePlaceOrderForm.css';
import useMediaQuery from "@mui/material/useMediaQuery";
import { MuiTelInput } from 'mui-tel-input';
import { TOAST_TYPE } from "../../utils/constants";
import total_area_icon from "../../assets/total-area.svg";
import LinkIcon from '@mui/icons-material/Link';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const UserFormModal = ({ userData, setUserData, _function, handleSuccess, disableEverything, featureSelected, polygonList, setStep }) => {
  const isLargerThan820 = useMediaQuery('(min-width:820px)');
  const isLargerThan1100 = useMediaQuery('(min-width:1100px)');

  let path = polygonList;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEmailValidation = () => {
    const {email} = userData;
    const forbiddenDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com'];

    // Create a regex pattern to exclude specific domains
    const emailPattern = new RegExp(`^[^@\\s]+@(?!(${forbiddenDomains.join('|').replace(/\./g, '\\.')})$)\\S+$`, 'i');

    if (!emailPattern.test(email)) {
      handleSuccess(TOAST_TYPE.WARNING, 'Only work emails are allowed.');
      return false
    } else {
      return true
      // setError('');
    }

  };


  const result = path.reduce(
    (total, currentValue) =>
      (total = parseFloat(total) + parseFloat(currentValue.measurement)),
    0
  );

  const handleSubmit = (e) => {
    e.preventDefault()

    if(handleEmailValidation()) {

      // run the save parcel function
      _function()
    }
 
  };

  const handleClick = () => {
    window.open(process.env.REACT_APP_MAP_LINK, '_blank', 'noopener,noreferrer');
  };

return (

<Box  sx={{background: "white", width: isLargerThan1100 ? "22%" : isLargerThan820 ? "30%" : "100%", position: isLargerThan820 ? "absolute" : "relative", left: isLargerThan820 ? "7%" : "0%", top: isLargerThan820 ? "3%" : "0%", zIndex: "780", borderRadius: "8px", marginBottom: !isLargerThan820 && "15%"}}>


<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", background: "#e8f6e0", borderRadius: "8px" }}>
{!disableEverything  && <IconButton sx={{margin: "10px", marginBottom: "0px", width: "fit-content"}} onClick={() => setStep(3)} className='ottermap-iconButton' aria-label="delete" size="medium">
  <KeyboardBackspaceIcon fontSize="inherit" />
</IconButton>}
<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px", paddingBottom: "0px"}} >
  <Typography sx={{fontSize: "clamp(20px, 1vw, 1.20vw) !important", fontWeight: "700"}}>{disableEverything ? "In progress" : "Place Order"}</Typography>
</Box>

<Box sx={{ padding: "20px"}}>
<Typography sx={{ fontSize: "clamp(13px, 1vw, 1vw) !important"}}>Selected features</Typography>
<Box sx={{display: "flex", background: "white", flexWrap: "wrap", gap: "0.5rem", maxHeight: "10vh", overflow: "scroll", padding: "3%", borderRadius: "10px"}}>
  {featureSelected?.map(item  => (
    <Box sx={{padding: "1% 2%", fontSize: "clamp(13px, 1vw, 1vw)", border: "1px solid #bdbdbd", color: "#616161", borderRadius: "50px", width: "fit-content"}}>{item?.name}</Box>
  ))}
</Box>
          <div
            style={{
              margin: "0px",
              marginTop: "10px",
              background: "white",
              height: "18px",
              fontSize: "clamp(13px, 1vw, 1vw)",
              borderRadius: "10px"
            }}
            className="total-area-container"
          >
            <img src={total_area_icon} alt="total_area_icon" />
            <p sx={{margin: "0px", fontSize: "clamp(13px, 1vw, 1vw)"}}>Total area {result.toFixed(2)} acre</p>
          </div>
</Box>
</Box>
       {!disableEverything &&  <Typography sx={{paddingLeft: "20px", marginTop: "10px", fontSize: "clamp(13px, 1vw, 1vw) !important"}}>Fill these details to place order</Typography>}
        <Box className="place-order-form-box" sx={{  padding: "10px 20px" }}>
        <form onSubmit={handleSubmit} className="place-order-form" >
          {!disableEverything && <>
            <TextField
            required
            disabled={disableEverything}
            margin="dense"
            variant="outlined"
            name="name"
            value={userData.name}
            onChange={handleChange}
            label="Name"
            type="text"
            fullWidth
            className='place-order-input'
            sx={{ fontSize: "clamp(14px, 0.80vw, 1vw) !important", }}
          />
          <TextField
            label="Work Email"
            name="email"
            disabled={disableEverything}
            value={userData.email}
            onChange={handleChange}
            required
            fullWidth
            type="email"
            margin="dense"
            className='place-order-input'
            variant="outlined"
            sx={{ fontSize: "clamp(14px, 0.80vw, 1vw) !important", marginTop: "15px !important"  }}
          />
          <MuiTelInput
            margin="dense"
            disabled={disableEverything}
            variant="outlined"
            value={userData.contact}
            continents={['NA']}
            defaultCountry={'US'}
            onChange={e => setUserData({ ...userData, contact: e })}
            className='place-order-input'
            preferredCountries={['US', 'CA']}
            sx={{ fontSize: "clamp(14px, 0.80vw, 1vw) !important", width: "100% !important", marginTop: "15px !important"  }}
          />
          <TextField
            label="Designation"
            name="role"
            disabled={disableEverything}
            value={userData.role}
            onChange={handleChange}
            fullWidth
            required
            type="text"
            className='place-order-input'
            margin="dense"
            variant="outlined"
            sx={{ fontSize: "clamp(14px, 0.80vw, 1vw) !important", marginTop: "15px !important"  }}
          />
          </>}
          <Button
            type="submit"
            loading={false}
            variant="contained"
            className={disableEverything ? "ottermap-button-disabled" : 'ottermap-button'}
            disabled={disableEverything}
            fullWidth
            sx={{ mt: 2, fontSize: "clamp(14px, 0.80vw, 1vw)" }}
          >
           {disableEverything ? "Mapping your property...." : "Place Order"}
           {disableEverything && 
              <div class="explore-anim-wrapper">
              <div class="explore-anim-element-wrapper">
                    <div class="explore-aem explore-anim-elem"></div>
                    <div class="explore-aem explore-anim-elem-2"></div>
                    <div class="explore-aem explore-anim-elem-3"></div>
                    <div class="explore-aem explore-anim-elem-4"></div>
              </div>
              </div>
           }
          </Button>
        </form>
         {disableEverything && <Typography sx={{marginTop: "10px", fontSize: "clamp(14px, 1.10vw, 1.10vw) !important"}}>We’ll email you when your map is ready. Meanwhile, check out this sample for a preview.</Typography>}
         {disableEverything && <Box onClick={handleClick} className='mapLink' sx={{display: "flex", alignItems: "center"}}><LinkIcon /><Typography sx={{fontSize: "clamp(14px, 1.10vw, 1.10vw) !important"}}>Map link</Typography></Box>}
         {disableEverything && <Typography sx={{marginTop: "10px", fontSize: "clamp(12px, 0.80vw, 0.80vw) !important"}}>Need help? Email us at <a className="email-link" href="mailto:info@ottermap.com">info@ottermap.com</a></Typography>}
        </Box>

</Box>
  );
};

export default UserFormModal;
